import React from 'react';
import Header from './Header';
import Modals from './Modals';
import Content from './Content';
import '../assets/css/App.scss';
let isMobile = false;
if (window.innerWidth >= 769) {
  isMobile = true;
}
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      submit: false,
      loaded: false,
      webp: true,
      agreement: 'agreement.pdf',
      confidential: 'confidential.pdf',
      pravila: 'rules.pdf',
    };
    this.showLkModal = this.showLkModal.bind(this);
    this.showChangeModal = this.showChangeModal.bind(this);
    this.showChangeDataModal = this.showChangeDataModal.bind(this);
    this.showFeedackModal = this.showFeedackModal.bind(this);
    this.info5Show = this.info5Show.bind(this);
    this.info7Show = this.info7Show.bind(this);
    this.info8Show = this.info8Show.bind(this);
    this.info4Show = this.info4Show.bind(this);
    this.prizeShow = this.prizeShow.bind(this);
  }
  showLkModal = () => {
    this.modals.lkShow();
  };
  loginShow = () => {
    this.modals.loginShow();
  };
  showFeedackModal = (showPrize) => {
    this.modals.feedbackShow(showPrize);
  };
  showChangeModal = () => {
    this.modals.changeShow();
  };
  showChangeDataModal = () => {
    this.modals.changeDataShow();
  };
  info5Show = (title) => {
    this.modals.info5Show(title);
  };
  info7Show = (title) => {
    this.modals.info7Show(title);
  };
  info8Show = (title) => {
    this.modals.info8Show(title);
  };
  info4Show = () => {
    this.modals.info4Show();
  };
  prizeShow = (name, price, id) => {
    this.modals.prizeShow(name, price, id);
  };
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  getPrizeMy = async () => {};
  componentDidMount() {}

  render() {
    return (
      <div className="app">
        <Header
          link={this.props.link}
          data={this.props.data}
          showLkModal={this.showLkModal}
          loginShow={this.loginShow}
          showChangeDataModal={this.showChangeDataModal}
          showChangeModal={this.showChangeModal}
        />
        <Content
          link={this.props.link}
          data={this.props.data}
          prizes={this.props.prizes}
          showLkModal={this.showLkModal}
          showFeedackModal={this.showFeedackModal}
          info5Show={this.info5Show}
          info7Show={this.info7Show}
          info8Show={this.info8Show}
          info4Show={this.info4Show}
          prizeShow={this.prizeShow}
          loginShow={this.loginShow}
        />
        <div className="footer-logo">
          <div className="footer-logo-box">GREENFIELD CLUB</div>
          <div className="footer-btn">
            <div className="footer-btn-top" onClick={this.scrollToTop}>
              <span className="ico ico-arrow-green"></span>наверх
              <span className="ico ico-star-green"></span>
            </div>
          </div>
        </div>
        <div className="box-section-info section-info">
          <div className="section-info-container">
            <div className="container">
              <p>
                Для участия в&nbsp;акции необходимо: в&nbsp;период с&nbsp;16&nbsp;февраля
                2024&nbsp;г.&nbsp;по&nbsp;24&nbsp;декабря 2024. приобрести в&nbsp;любом магазине
                на&nbsp;территории&nbsp;РФ любую упаковку чая Greenfield{' '}
                <sup class="reg">&reg;</sup>, зарегистрировать чек с&nbsp;покупкой на&nbsp;сайте
                www.greenfield-club.ru или в&nbsp;чат-боте, накопить баллы (каждые 10&nbsp;рублей
                равняются 1&nbsp;баллу) и&nbsp;обменять их&nbsp;на&nbsp;доступные подарки. Общий
                срок проведения акции (включая срок выдачи подарков): с&nbsp;16&nbsp;февраля
                2024&nbsp;г.&nbsp;по&nbsp;31&nbsp;декабря 2024 г. Количество подарков ограничено.
                Подарки могут отличаться от&nbsp;изображений нарекламных материалах. Информация
                об&nbsp;организаторе акции, сроках и&nbsp;правилах ее проведения, количестве
                подарков, сроках, месте и&nbsp;порядке их&nbsp;вручения размещенана сайте
                www.greenfield-club.ru. Организатор акции&nbsp;&mdash; ООО &laquo;Эктив
                ПроджектМенеджмент&raquo; (ООО &laquo;АРМ&raquo;), ИНН 7705609429.
              </p>
            </div>
          </div>
        </div>
        <Modals
          runWidget={this.runWidget}
          link={this.props.link}
          getPrizeMy={this.getPrizeMy}
          data={this.props.data}
          ref={(instance) => {
            this.modals = instance;
          }}
        />
        <div id="apm-widget"></div>
      </div>
    );
  }
}
